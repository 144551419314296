import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('@/views/Main.vue'),
    children: [{
      path: '/user',
      name: 'user',
      component: () => import('@/views/item/User.vue')
    },
    {
      path: '/shop',
      name: 'shop',
      component: () => import('@/views/item/Shop.vue'),
    },
    {
      path: '/shop/shopadd',
      name: 'shopadd',
      component: () => import('@/views/shop/shopAdd'),
    },
    {
      path: '/shop/shopchange',
      name: 'shopchange',
      component: () => import('@/views/shop/shopChange'),
    },
    {
      path: '/order',
      name: 'order',
      component: () => import('@/views/item/Order.vue')
    },
    {
      path: '/order/orderRes',
      name: 'orderRes',
      component: () => import('@/views/order/orderRes.vue')
    },
    {
      path: '/integral',
      name: 'integral',
      component: () => import('@/views/item/Integral.vue')
    },
    {
      path: '/config',
      name: 'config',
      component: () => import('@/views/item/Config.vue')
    }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
