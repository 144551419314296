import axios from 'axios'
import { Loading } from "element-ui";

axios.defaults.withCredentials = true

const service = axios.create({
    baseURL: 'https://exchange.jingxiansuo.com/houtai/',
    timeout: 30000 // 设置请求超时时间
})
axios.defaults.withCredentials = true
let loading = "";
// 请求拦截器
service.interceptors.request.use(
    (config) => {
        // 在请求发送之前做一些处理
        if (!(config.headers['Content-Type'])) {
            loading = Loading.service({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(255,255,255,0.7)",
                customClass: "request-loading",
            });
            if (config.method == 'post') {
                config.headers['Content-Type'] =
                    'application/json;charset=UTF-8'
                for (var key in config.data) {
                    if (config.data[key] === '') {
                        delete config.data[key]
                    }
                }
                config.data = JSON.stringify(config.data)
            } else {
                config.headers['Content-Type'] =
                    'application/x-www-form-urlencoded;charset=UTF-8'
                config.data = JSON.stringify(config.data)
            }
        }
        const token = localStorage.getItem('token')
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        } else {
            if (config.url !== '/adminLogin') {
                location.href = "/"
            }
        }
        return config
    },
    (error) => {
        loading.close();
        console.log(error)
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    (response) => {
        if (response.status == 401) {
            localStorage.clear()
            location.href = "/"
        }
        loading.close();
        const dataAxios = response.data
        return dataAxios
    },
    (error) => {
        if (error.response.status == 401) {
            localStorage.clear()
            location.href = "/"
        }
        return Promise.reject(error)
    }
)

export default service